export default [
  {
    title: 'Komcards',
    customicon: `<svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.853 6.49902C14.036 6.49902 14.9948 7.39403 14.9948 8.49834C14.9948 9.60266 14.036 10.4977 12.853 10.4977C11.67 10.4977 10.7112 9.60266 10.7112 8.49834C10.7112 7.39403 11.67 6.49902 12.853 6.49902Z" fill="#808080"/>
        <path d="M19.3534 16.4978H19.3463L1.64082 16.4226C0.731076 16.4182 -0.00349102 15.7271 1.24804e-05 14.8779C0.00468382 14.0308 0.741587 13.3462 1.64783 13.3462H1.656L17.7044 13.4149C17.7044 13.4149 17.7055 13.4149 17.7055 13.4138V6.22215C17.7055 6.19817 17.6927 6.17418 17.6728 6.16001L14.028 3.59164C14.014 3.58183 13.9953 3.57638 13.9778 3.57638H5.33001C4.40508 3.57638 3.62964 2.86997 3.64832 2.00658C3.66701 1.14318 4.39691 0.5 5.29614 0.5H13.9755C14.71 0.5 15.4259 0.72348 16.0122 1.13664L19.657 3.70502C20.4978 4.29805 21 5.23884 21 6.22324V14.9618C21 15.3706 20.8248 15.7631 20.5142 16.052C20.2059 16.3387 19.7878 16.5 19.3522 16.5L19.3534 16.4978Z" fill="#808080"/>
        <path d="M12.8298 16.4226H1.64781C0.738071 16.4226 0 15.7347 0 14.8844C0 14.0341 0.738071 13.3462 1.64781 13.3462H12.8298C13.7396 13.3462 14.4776 14.0352 14.4776 14.8844C14.4776 15.7336 13.7396 16.4226 12.8298 16.4226Z" fill="#666666"/>
        </svg>`,
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Dashboard',
        tagVariant: 'light-warning',
        route: 'komcards-dashboard',
        resource: 'Komcards',
        action: 'manage',
        komicon: 'k-home-hashtag',
      },
      {
        title: 'Verifikasi Partner',
        tagVariant: 'light-warning',
        route: 'verification-komcards',
        resource: 'Komcards',
        action: 'manage',
        komicon: 'k-user-tick',
      },
    ],
  },
]
