import komtim from '../komtim'
import partner from './partner'
import management from './management'
import kompack from './kompack'
import komship from './komship'
import notification from './notification'
import affiliate from './affiliate'
import withdrawal from './withdrawal'
import komclass from './komclass'
import pumkm from './pumkm'
import komcards from './komcards'
import adminManagement from './adminManagement'

// Komform
import komform from '../komform'

// Array of sections
export default [
  ...notification,
  ...withdrawal,
  ...komtim,
  ...komship,
  ...kompack,
  ...komclass,
  ...komcards,
  ...affiliate,
  ...pumkm,
  ...komform,
  ...management,
  ...partner,
  ...adminManagement,
]
