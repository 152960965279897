export default [
  {
    title: 'Pencairan',
    komicon: 'k-receive-square',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Partner',
        tagVariant: 'light-warning',
        komicon: 'k-user-tick',
        route: 'cod-pencairan',
        resource: 'Pencairan',
        action: 'manage',
      },
      {
        title: 'Mitra',
        tagVariant: 'light-warning',
        komicon: 'k-profile-2user',
        route: 'withdrawal-mitra',
        resource: 'Pencairan',
        action: 'manage',
      },
      {
        title: 'Affiliate',
        tagVariant: 'light-warning',
        komicon: 'k-people',
        route: 'withdrawal-affiliate',
        resource: 'Pencairan',
        action: 'manage',
      },
      {
        title: 'Kolaborator Open API',
        tagVariant: 'light-warning',
        komicon: 'k-hashtag-1',
        route: 'withdrawal-collaborator',
        resource: 'Pencairan',
        action: 'manage',
      },
    ],
  },
]
